/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FunctionComponent, h } from "preact";
import { useState } from 'preact/hooks';
import style from './style.css';
import { ProductModalCategoryType, ProductType } from '../../data';
import Icon from "../../../../../../components/Icon/Icon";

interface InfoHotspotProps {
  id: string;
  product: ProductType;
  onClick: (p: ProductType, option: ProductModalCategoryType) => void;
}

const InfoHotspot: FunctionComponent<InfoHotspotProps> = ({ id, product, onClick }) => {
  const [displayMenuOptions, setDisplayMenuOptions] = useState<boolean>(false)

  const menu = (
    <div class={style.menuOptionsWrapper}>
      <div class={style.menuOption} onClick={() => onClick(product, 'product')}>
        Product Recommendation
      </div>
      <div class={style.menuOption} onClick={() => onClick(product, 'cleaning-tips')}>
        Cleaning Tips
      </div>
    </div>
  )

  return (
    <div
      id={id}
      class={style.infoHotspot}
      onMouseEnter={() => setDisplayMenuOptions(true)}
      onMouseLeave={() => setDisplayMenuOptions(false)}
    >
      <Icon icon="info" iconWrapperClass={style.hotspotIconWrapper} />
      {displayMenuOptions && menu}
    </div>
  )
}

export default InfoHotspot;