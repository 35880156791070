import { FunctionalComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import Icon from '../Icon/Icon';
import style from './style.css';

interface ModalProps {
  display: boolean;
  children: h.JSX.Element;
  onClose?: (e?: MouseEvent) => void;
  title?: string | h.JSX.Element;
  overlay?: boolean;
  opacity?: boolean;
}

const Modal: FunctionalComponent<ModalProps> = ({ display, children, onClose, title, overlay = true, opacity = false }) => {
  const modalOverlayEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalOverlayEl.current || !onClose || !overlay) return;

    modalOverlayEl.current.addEventListener("click", (e) => {
      if (e.target === modalOverlayEl.current) onClose();
    });

    return () => {
      modalOverlayEl.current?.removeEventListener("click", (e) => {
        if (e.target === modalOverlayEl.current) onClose();
      });
    }
  }, [display, onClose]);

  return display ? (
    <div class={`${style.modalOverlay} ${overlay ? '' : style.transparent}`} ref={modalOverlayEl}>
      <div class={style.modalWrapper}>
        <div class={`${style.modal} ${style.enterModalEffect} ${opacity ? style.opacity : ''}`}>
          {title && <div class={style.modalHeader}>
            <div style={style.modalTitle}>{title}</div>
            {onClose && <Icon icon="outlined-close" iconWrapperClass={style.closeIconWrapper} onClick={() => onClose()} />}
          </div>
          }
          <div class={style.modalBody}>
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
