export type ProductType = 'disinfecting-wipes'
  | 'disinfecting-mist'
  | 'toilet-wand'
  | 'disinfecting-mopping-cloth'
  | 'tilex-daily-shower-cleaner'
  | 'windex-glass-cleaner'

export interface InfoHotspot {
  id: string;
  product: ProductType;
  position: { yaw: number; pitch: number };
}

export type ProductModalCategoryType = 'product' | 'cleaning-tips';

export const data = {
  scene: {
    id: "1-bathroom-pano",
    name: "bathroom-pano",
    levels: [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ],
    faceSize: 1750,
    initialViewParameters: {
      yaw: -0.17876453271422044,
      pitch: 0.496688457078589,
      fov: 1.4973696188456629
    },
    linkHotspots: [],
    infoHotspots: [
      {
        id: 'disinfectingWipes',
        product: 'disinfecting-wipes',
        position: { yaw: -2.38, pitch: 0.38 },
      },
      {
        id: 'disinfectingMist',
        product: 'disinfecting-mist',
        position: { yaw: 0.82, pitch: 0.17 },
      },
      {
        id: 'toiletWand',
        product: 'toilet-wand',
        position: { yaw: 2.08, pitch: 2.22 },
      },
      {
        id: 'disinfectingMoppingCloth',
        product: 'disinfecting-mopping-cloth',
        position: { yaw: 2.60, pitch: 2.20 },
      },
      {
        id: 'tilexDailyShowerCleaner',
        product: 'tilex-daily-shower-cleaner',
        position: { yaw: 0.08, pitch: .16 },
      },
      {
        id: 'windexGlassCleaner',
        product: 'windex-glass-cleaner',
        position: { yaw: -1.65, pitch: 0 },
      }
    ] as InfoHotspot[]
  },
  name: "Bathroom",
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: false,
    fullscreenButton: false,
    viewControlButtons: false
  }
};

