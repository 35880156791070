export const AUTH_HASH_KEY = 'hash';

async function getSecretHash() {
  return fetch('cca8da15-9b60-44c6-bed1-37bef85668ec.txt')
    .then(resp => resp.text())
    .catch(() => console.error('Cannot find secret file'))
}

export async function authenticate(pwd: string): Promise<string> {
  const secretHash = await getSecretHash() || ""
  const encodedPwd = window.btoa(pwd)

  return new Promise((resolve, reject) => {
    if (encodedPwd === secretHash) {
      sessionStorage.setItem(AUTH_HASH_KEY, secretHash)
      return resolve(secretHash)
    }
    return reject('Password incorrect')
  })
}


export async function isAuthenticated() {
  const secretHash = await getSecretHash()
  const sessionHash = sessionStorage.getItem(AUTH_HASH_KEY)

  if (!sessionHash) return false
  if (sessionHash !== secretHash) return false
  return true
}
