import { FunctionalComponent, h } from "preact";
import { useState, useRef } from "preact/hooks";
import { validate, ValidationRule } from "../../util";
import style from "./style.css";

type inputType = "email" | "password" | "text";

export interface InputProps {
  type: inputType;
  label?: string;
  onInput?: Function;
  value: string;
  errors?: string[];
  validation?: ValidationRule;
  onBlur?: Function;
  onFocus?: Function;
  readOnly?: boolean;
  inputWrapperClass?: string;
  disabled?: boolean;
}

const Input: FunctionalComponent<InputProps> = ({
  type = "text",
  label,
  onInput,
  value,
  errors = [],
  validation,
  onBlur,
  readOnly,
  inputWrapperClass,
  onFocus,
  disabled,
}: InputProps) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const input = useRef<HTMLInputElement>(null);

  const handleOnInput = (e: Event) => {
    if (validationErrors.length) setValidationErrors([]);
    if (onInput) onInput(e);
  };

  const handleFocus = (isFocused: boolean) => {
    if (onFocus) onFocus();
    setIsFocused(isFocused);
  };

  const handleOnBlur = (e: Event) => {
    handleFocus(false);

    if (onBlur) onBlur(e);
    if (!validation) return;
    if (!label) throw new Error("Must pass label prop with validations");

    const _validationErrors = validate(validation, label, value);
    setValidationErrors(_validationErrors);
  };

  const handleLabelClick = (e: UIEvent) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    input.current && (input.current! as HTMLInputElement).focus();
  };

  const hasErrors = errors?.length || validationErrors.length;
  const isActive = value !== "" || isFocused;

  const displayError = () => {
    if (!hasErrors) return;

    return [...validationErrors, ...errors].map((err) => (
      <div key={err} class={style.errorText}>
        {err}
      </div>
    ));
  };

  return (
    <div
      class={`
      ${style.inputWrapper}
      ${hasErrors ? style.error : ""}
      ${isActive ? style.active : ""}
      ${readOnly ? style.readOnly : ""}
      ${isFocused ? style.isFocused : ""}
      ${disabled ? style.disabled : ""}
      ${inputWrapperClass}
      `}
    >
      <label onClick={handleLabelClick}>{label}</label>
      <input
        ref={input}
        onInput={handleOnInput}
        class={`${style.input} ${hasErrors ? style.error : ""}`}
        type={type}
        value={value}
        onFocus={() => handleFocus(true)}
        onBlur={handleOnBlur}
        readOnly={readOnly}
        autoComplete="none"
      />
      {displayError()}
    </div>
  );
};

export default Input;