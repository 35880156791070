import { FunctionalComponent, h } from 'preact';
import style from './style.css';

type ButtonType = 'primary' | 'white' | 'dark';
interface ButtonProps {
  children: string;
  onClick: (e: MouseEvent) => void;
  buttonType: ButtonType;
  disabled?: boolean;
}

const Button: FunctionalComponent<ButtonProps> = ({ children, onClick, buttonType, disabled = false }) => {
  const getButtonType = (type: ButtonType) => {
    switch (type) {
      case 'primary':
        return style.primary
      case 'dark':
        return style.dark
      case 'white':
        return style.white
    }
  }

  return (
    <button class={`${style.button} ${getButtonType(buttonType)} ${disabled ? style.disabled : ''}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
