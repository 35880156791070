/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Fragment, h } from "preact";
import style from './style.css';
import Marzipano from 'marzipano';
import { data } from './data';
import { RoutePath } from "../../../../models/route";
import { route } from 'preact-router';
class LobbyPano extends Component {
  viewer: any;
  pano: any;

  componentDidMount() {
    const viewerOpts = {
      controls: { mouseViewMode: 'drag' },
    };

    this.viewer = new Marzipano.Viewer(this.pano, viewerOpts);

    const urlPrefix = "/assets/tiles";
    const { id, initialViewParameters, levels, faceSize } = data.scene
    const source = Marzipano.ImageUrlSource.fromString(
      `${urlPrefix}/${id}/{z}/{f}/{y}/{x}.jpg`,
      { cubeMapPreviewUrl: `${urlPrefix}/${id}/preview.jpg` }
    );
    const limiter = Marzipano.util.compose(
      Marzipano.RectilinearView.limit.traditional(2 * faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180),
      Marzipano.RectilinearView.limit.yaw(-Math.PI / 8, Math.PI / 8),
      Marzipano.RectilinearView.limit.pitch(-Math.PI / 8, Math.PI / 8),
    );

    const view = new Marzipano.RectilinearView(initialViewParameters, limiter);
    const geometry = new Marzipano.CubeGeometry(levels);

    const scene = this.viewer.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    });

    const panoScene = {
      data: data.scene,
      scene,
      view,
    }

    const comingSoonImg = document.createElement('img');
    comingSoonImg.src = '/assets/images/lobby-view-coming-soon.png';
    const toRoomImg = document.querySelector('#toRoomCardWrapperEl');

    panoScene.scene.switchTo();

    // Get the hotspot container for scene.
    const container = panoScene.scene.hotspotContainer();
    // Create hotspot with different sources.
    container.createHotspot(comingSoonImg, { yaw: 0.0135, pitch: -0.102 },
      { perspective: { radius: 840, extraTransforms: "rotateX(5deg)" } });
    container.createHotspot(toRoomImg, { yaw: 0.0135, pitch: -0.102 },
      { perspective: { radius: 840, extraTransforms: "translate(-260px,-160px) rotateX(5deg)" } });
  }

  render() {
    return (
      <Fragment>
        <div class={style.panoContainer} ref={pano => this.pano = pano} />
        <div id="toRoomCardWrapperEl" class={style.toRoomCardWrapper}>
          <img src="assets/images/lobby-view-to-room-card.png" onClick={() => route(RoutePath.Bathroom, true)} />
        </div>
      </Fragment>
    )
  }
}

export default LobbyPano;