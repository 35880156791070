import { FunctionalComponent, h } from "preact";
import style from "./style.css";

interface LoaderProps {
  fullScreen?: boolean;
}

const Loader: FunctionalComponent<LoaderProps> = ({ fullScreen }) => {
  return (
    <div class={`${style.loaderWrapper} ${fullScreen ? style.fullScreen : ""}`}>
      <div class={style.spinner}><div /><div /><div /><div /></div>
    </div>
  );
};

export default Loader;