import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import Button from '../../components/Button/Button';
import { RoutePath } from '../../models/route';
import style from './style.css';

const Home: FunctionalComponent = () => {
  return (
    <div class={style.home}>
      <header class={style.topbar} style={{ backgroundImage: `url('/assets/images/home-view-topbar.png')` }}>
        <img src='/assets/images/home-view-topbar.png' style={{ visibility: `hidden`, width: '100%' }} />
      </header>
      <main class={style.body}>
        <section class={style.banner} style={{ backgroundImage: `url('/assets/images/home-view-banner.png')` }}>
          <img src='/assets/images/home-view-banner.png' style={{ visibility: `hidden`, width: '100%' }} />
          <div class={style.bannerOverlay}>
            <div class={style.bannerOverlayContent}>
              <img src='/assets/images/home-view-banner-content.png' style={{ width: '100%' }} />
              <div class={style.buttonWrapper}>
                <Button onClick={() => route(RoutePath.Lobby, true)} buttonType="primary">Launch Experience</Button>
              </div>
            </div>
          </div>
        </section>
        <div class={style.shop} style={{ backgroundImage: `url('/assets/images/home-view-body.png')` }}>
          <img src='/assets/images/home-view-body.png' style={{ visibility: `hidden`, width: '100%' }} />
        </div>
      </main>
    </div>
  );
};

export default Home;
