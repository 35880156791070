/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, h } from "preact";
import style from './style.css';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ProductType } from "../../data";
import Icon from "../../../../../../components/Icon/Icon";
import Loader from "../../../../../../components/Loader/Loader";

interface ProductPreviewProps {
  product: ProductType | null;
}

interface ProductPreviewState {
  loading: boolean;
}

class ProductPreview extends Component<ProductPreviewProps, ProductPreviewState> {
  state: ProductPreviewState = {
    loading: true
  }

  sceneEl: HTMLCanvasElement | null = null;
  scene: any;

  componentDidMount() {
    this.initScene()
  }

  private initScene = () => {
    if (!this.props.product) return

    if (this.state.loading === false) this.setState({ loading: true })

    this.scene = new THREE.Scene()
    this.scene.background = null;

    // Sizes
    const sizes = {
      width: 540,
      height: 530
    }

    // Camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
    camera.position.z = this.initialProductZoom()

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      canvas: this.sceneEl
    })
    renderer.setSize(sizes.width, sizes.height)

    // Controls
    const controls = new OrbitControls(camera, this.sceneEl);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    // Light
    // const light = new THREE.PointLight(0xffffff, .4);
    // light.position.set(10, 10, 10);
    // this.scene.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff);
    this.scene.add(ambientLight);

    // Object
    this.uploadImage()

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(this.scene, camera);
    };

    animate()
  }

  private initialProductZoom = () => {
    switch (this.props.product) {
      case 'disinfecting-mist':
        return 9;
      case 'disinfecting-mopping-cloth':
        return 12;
      case 'disinfecting-wipes':
        return 8;
      case 'tilex-daily-shower-cleaner':
        return 9;
      case 'toilet-wand':
        return 14;
      case 'windex-glass-cleaner':
        return 9
    }
  }

  private uploadImage = () => {
    // Object
    const mtlLoader = new MTLLoader();
    mtlLoader.load(`/assets/3d/${this.getProductImageFileName()}.mtl`, (materials: any) => {
      materials.preload();

      const objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      objLoader.load(`/assets/3d/${this.getProductImageFileName()}.obj`, this.addObjectToScene);
    });
  }

  private addObjectToScene = (object: any) => {
    this.scene.add(object);
    this.setState({ loading: false })
  }

  private getProductImageFileName = (): string => {
    switch (this.props.product) {
      case 'disinfecting-mist':
        return '1000000027_Clorox_DisinfectMist_MultiSurface_LmnGrassMand_16oz';
      case 'disinfecting-mopping-cloth':
        return '4460032358_Clorox_BleachFree_DisinfectWetMopCloths_24ct';
      case 'disinfecting-wipes':
        return '4460001628_Clorox_DisinfectingWipes_CrispLemon_1lb3-7oz_75ct';
      case 'tilex-daily-shower-cleaner':
        return '4460001260_Clorox_Tilex_DailyShowerCleaner_32oz';
      case 'toilet-wand':
        return '4460003191_Clorox_ToiletWand_CleaningKit';
      case 'windex-glass-cleaner':
        return '1980000202_Windex_Original_26oz';
      default:
        return '';
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div class={style.productPreview}>
        {loading &&
          <div class={style.loading}>
            <Loader />
          </div>}
        <Icon icon="rotate-box" iconWrapperClass={style.rotateBoxIconWrapper} />
        <canvas class={style.sceneContainer} ref={scene => this.sceneEl = scene} />
      </div>
    )
  }
}

export default ProductPreview;