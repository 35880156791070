export const rotateBoxIcon = `
<svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.2489 23.1139L31.7637 25.0542L34.7614 25.8038L35.2466 23.8636L32.2489 23.1139Z" fill="white"/>
<path d="M24.2464 21.1203L23.7612 23.0605L29.7663 24.5622L30.2515 22.622L24.2464 21.1203Z" fill="white"/>
<path d="M17.52 23.77L16.48 22.07L19.83 20L22.24 20.6L21.76 22.54L20.17 22.14L17.52 23.77Z" fill="white"/>
<path d="M14.4642 23.3021L11.3384 25.2251L12.3864 26.9286L15.5122 25.0055L14.4642 23.3021Z" fill="white"/>
<path d="M27.17 36.1501L6 30.8501V28.5101L9.73 26.2201L10.77 27.9201L8.39 29.3901L26.83 34.0001L38.61 26.7501L36.76 26.2901L37.24 24.3501L41 25.2901V27.6301L27.17 36.1501Z" fill="white"/>
<path d="M27.17 16.14L6 10.85V8.51001L19.83 0L41 5.29001V7.63L27.17 16.14ZM8.39 9.39001L26.83 14L38.61 6.75L20.17 2.14001L8.39 9.39001Z" fill="white"/>
<path d="M8 10.0698H6V29.0698H8V10.0698Z" fill="white"/>
<path d="M28 15.0698H26V35.0698H28V15.0698Z" fill="white"/>
<path d="M21 18.0698H19V21.0698H21V18.0698Z" fill="white"/>
<path d="M21 1.06982H19V4.06982H21V1.06982Z" fill="white"/>
<path d="M21 6.06982H19V10.0698H21V6.06982Z" fill="white"/>
<path d="M21 12.0698H19V16.0698H21V12.0698Z" fill="white"/>
<path d="M41 6.06982H39V26.0698H41V6.06982Z" fill="white"/>
<path d="M25 44.0699H23.08C9.92 44.0699 0 37.8399 0 29.5699C0 26.7799 0.389999 23.8699 3.19 21.4199L4.5 22.9299C2.43 24.7399 2 26.9299 2 29.5699C2 36.6999 11.06 42.0699 23.08 42.0699H25V44.0699Z" fill="white"/>
<path d="M28.0999 43.8398L27.8999 41.8398C38.5599 40.7598 45.9999 35.7098 45.9999 29.5698C46.0919 28.137 45.8093 26.7048 45.1798 25.4143C44.5503 24.1238 43.5957 23.0194 42.4099 22.2098L43.5899 20.5898C45.036 21.585 46.2015 22.9358 46.9739 24.5121C47.7463 26.0885 48.0997 27.8372 47.9999 29.5898C47.9999 36.7698 39.8199 42.6398 28.0999 43.8398Z" fill="white"/>
<path d="M21.71 47.7801L20.29 46.3701L23.59 43.0701L20.29 39.7801L21.71 38.3701L26.41 43.0701L21.71 47.7801Z" fill="white"/>
</svg>
`