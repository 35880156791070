/* eslint-disable react/no-danger */
import { FunctionComponent, h } from "preact";
import style from "./style.css";
import { closeIcon } from "./icons/close";
import { cartIcon } from "./icons/cart";
import { cloroxLogoIcon } from "./icons/clorox-logo";
import { downCarrotIcon } from "./icons/down-carrot";
import { infoIcon } from "./icons/info";
import { lobbyIcon } from "./icons/lobby";
import { outlinedCloseIcon } from "./icons/outlined-close";
import { starsIcon } from "./icons/stars";
import { walmartLogoIcon } from "./icons/walmart-logo";
import { upArrowIcon } from "./icons/up-arrow";
import { downArrowIcon } from "./icons/down-arrow";
import { leftArrowIcon } from "./icons/left-arrow";
import { rightArrowIcon } from "./icons/right-arrow";
import { eyeIcon } from "./icons/eye";
import { zoomInIcon } from "./icons/zoom-in";
import { zoomOutIcon } from "./icons/zoom-out";
import { rotateBoxIcon } from "./icons/rotate-box";

export type IconType =
  | "up-arrow"
  | "down-arrow"
  | "left-arrow"
  | "right-arrow"
  | "eye"
  | "zoom-in"
  | "zoom-out"
  | "cart"
  | "clorox-logo"
  | "lobby"
  | "outlined-close"
  | "stars"
  | "info"
  | "walmart-logo"
  | "close"
  | "rotate-box"
  | "down-carrot";

interface IconProps {
  id?: string;
  icon: IconType;
  onClick?: (e?: MouseEvent) => void;
  iconWrapperClass?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  id,
  icon,
  onClick,
  iconWrapperClass,
}) => {
  const getIcon = (): string => {
    switch (icon) {
      case "cart":
        return cartIcon;
      case "clorox-logo":
        return cloroxLogoIcon;
      case "down-carrot":
        return downCarrotIcon;
      case "outlined-close":
        return outlinedCloseIcon;
      case "stars":
        return starsIcon;
      case "close":
        return closeIcon;
      case "info":
        return infoIcon;
      case "walmart-logo":
        return walmartLogoIcon;
      case "lobby":
        return lobbyIcon;
      case "up-arrow":
        return upArrowIcon;
      case "down-arrow":
        return downArrowIcon;
      case "left-arrow":
        return leftArrowIcon;
      case "right-arrow":
        return rightArrowIcon;
      case "eye":
        return eyeIcon;
      case "zoom-in":
        return zoomInIcon;
      case "zoom-out":
        return zoomOutIcon;
      case "rotate-box":
        return rotateBoxIcon;
    }
  };

  return (
    <i
      id={id}
      class={`
      ${style.iconWrapper} 
      ${onClick ? style.clickable : ""} 
      ${iconWrapperClass ? iconWrapperClass : ""}
      `}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: getIcon() }}
    />
  )
};

export default Icon;