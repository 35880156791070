export interface ValidationRule {
  required: boolean;
  message?: string;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function validate(
  rule: ValidationRule,
  label: string,
  value: any
): string[] {
  let errors: string[] = [];

  if (rule.required && !value) {
    const requiredErrorMsg = rule.message || `${capitalize(label)} is required`;
    errors = [...errors, requiredErrorMsg];
  }

  return errors;
}
