import { FunctionComponent, h } from "preact";
import { route } from "preact-router";
import { useCallback, useEffect, useState } from "preact/hooks";
import style from './style.css';
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { RoutePath } from "../../models/route";
import { authenticate } from "../../util";

const EnterPassword: FunctionComponent = () => {
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const handleAuthenticate = useCallback((e?: MouseEvent) => {
    if (e) e.preventDefault()

    authenticate(password)
      .then(() => route(RoutePath.Home))
      .catch(err => setErrors([err]))
  }, [password])

  const handleKeyDownEvent = useCallback((e: KeyboardEvent) => {    
    if (!password || e.key !== 'Enter') return
    handleAuthenticate()
  }, [handleAuthenticate, password])


  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownEvent)

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent)
    }
  }, [handleKeyDownEvent])

  return (
    <div class={style.enterPassword}>
      <div class={style.formWrapper}>
        <Input
          label="Password"
          value={password}
          onInput={(e: any) => setPassword(e.target.value)}
          type="password"
          errors={errors}
          validation={{ required: true }}
        />
        <Button buttonType='dark' onClick={handleAuthenticate} disabled={!password}>Enter</Button>
      </div>
    </div>
  )
}

export default EnterPassword;