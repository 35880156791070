import { closeIcon } from "../../components/Icon/icons/close";
import "./style.css";

export enum NotificationDuration {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 7000,
  NEVER = 0,
}

export default function toastr() {
  const getWrapperEl = () => {
    let wrapperEl = document.querySelector(".toastr-wrapper");
    if (wrapperEl) return wrapperEl;

    wrapperEl = document.createElement("div");
    wrapperEl.classList.add("toastr-wrapper");
    const body = document.querySelector("body");
    body?.appendChild(wrapperEl);

    return wrapperEl;
  };

  const createToastrEl = () => {
    const toastrEl = document.createElement("div");
    toastrEl.classList.add("toastr-element");
    return toastrEl;
  };

  const createCloseIconEl = () => {
    const closeIconEl = document.createElement("div");
    closeIconEl.classList.add("close-icon");
    closeIconEl.innerHTML = closeIcon;
    return closeIconEl;
  };

  const createToastrMsgWrapperEl = () => {
    const toastrMsgWrapperEl = document.createElement("div");
    toastrMsgWrapperEl.classList.add("toastr-msg-wrapper");
    return toastrMsgWrapperEl;
  };

  const show = (type: string, msg: string, duration: NotificationDuration) => {
    const el = createToastrEl();
    el.setAttribute("id", "toastrElement");
    el.classList.add(type);
    const msgWrapperEl = createToastrMsgWrapperEl();
    msgWrapperEl.innerHTML = msg;

    const wrapperEl = getWrapperEl();
    const closeIconEl = createCloseIconEl();

    closeIconEl.onclick = () => wrapperEl.removeChild(el);
    el.appendChild(msgWrapperEl);
    el.appendChild(closeIconEl);
    wrapperEl.appendChild(el);
    wrapperEl.setAttribute(
      "style",
      `left: calc(50vw - ${el.getBoundingClientRect().width / 2}px)`
    );

    if (duration === NotificationDuration.NEVER) return;

    setTimeout(() => {
      wrapperEl.removeChild(el);
    }, duration);
  };

  return Object.freeze({
    success(msg: string, duration: NotificationDuration = 3000) {
      show("success", msg, duration);
    },
    info(msg: string, duration: NotificationDuration = 3000) {
      show("info", msg, duration);
    },
    warning(msg: string, duration: NotificationDuration = 3000) {
      show("warning", msg, duration);
    },
    danger(msg: string, duration: NotificationDuration = 3000) {
      show("danger", msg, duration);
    },
    clear() {
      document.querySelectorAll(".toastr-wrapper").forEach((el) => el.remove());
    },
  });
}