import { FunctionComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import Button from "../../../../../../components/Button/Button";
import Icon from "../../../../../../components/Icon/Icon";
import Modal from "../../../../../../components/Modal/Modal";
import toastr, { NotificationDuration } from "../../../../../../libs/toastr";
import { generateUuidv4 } from "../../../../../../util";
import { ProductModalCategoryType, ProductType } from "../../data";
import ProductPreview from "../ProductPreview/ProductPreview";
import style from './style.css';

interface ProductModalProps {
  display: boolean;
  onClose: (e?: MouseEvent) => void;
  product: ProductType | null;
  category: ProductModalCategoryType;
}

const ProductModal: FunctionComponent<ProductModalProps> = ({ display, onClose, product, category }) => {
  const [_product, setProduct] = useState<ProductType | null>(product);
  const [_category, setCategory] = useState<ProductModalCategoryType>(category);

  useEffect(() => {
    setProduct(product)
  }, [product])

  useEffect(() => {
    setCategory(category)
  }, [category])

  const modalHeader = (
    <div class={style.modalHeader}>
      <Button onClick={() => setCategory('product')} buttonType={_category === 'product' ? 'white' : 'dark'}>Product Card</Button>
      <div style={{ width: '20px' }} />
      <Button onClick={() => setCategory('cleaning-tips')} buttonType={_category === 'cleaning-tips' ? 'white' : 'dark'}>Cleaning Tips</Button>
    </div>
  )

  const getPreviewImages = () => {
    switch (_product) {
      case 'disinfecting-wipes':
        return <img src="/assets/images/modal-clorox-wipes-preview-imgs.png" class={style.previewImagesImage} />
      case 'disinfecting-mist':
        return <img src="/assets/images/modal-clorox-mist-preview-imgs.png" class={style.previewImagesImage} />
      case 'disinfecting-mopping-cloth':
        return <img src="/assets/images/modal-clorox-mop-cloth-preview-imgs.png" class={style.previewImagesImage} />
      case 'tilex-daily-shower-cleaner':
        return <img src="/assets/images/modal-clorox-tilex-preview-imgs.png" class={style.previewImagesImage} />
      case 'toilet-wand':
        return <img src="/assets/images/modal-clorox-wand-preview-imgs.png" class={style.previewImagesImage} />
      case 'windex-glass-cleaner':
        return <img src="/assets/images/modal-glass-cleaner-preview-imgs.png" class={style.previewImagesImage} />
    }
  }

  const getProductDescription = () => {
    switch (_product) {
      case 'disinfecting-wipes':
        return "Clorox Disinfecting Wipes, Bleach Free Cleaning Wipes, Crisp Lemon, 75 Count"
      case 'disinfecting-mist':
        return "Clorox Disinfecting Mist"
      case 'disinfecting-mopping-cloth':
        return "Clorox Disinfecting Mopping Cloth - Rain Clean - 24ct"
      case 'tilex-daily-shower-cleaner':
        return "Clorox Plus Tilex Daily Shower Cleaner, Spray Bottle, 32 Ounces"
      case 'toilet-wand':
        return "Clorox ToiletWand Disposable Toilet Cleaning System - ToiletWand Storage Caddy and 6 Refill Heads"
      case 'windex-glass-cleaner':
        return "Windex Glass Cleaner, Original Blue, Spray Bottle, 23 fl oz"
    }
  }

  const handleAddProductToCart = () => {
    toastr().warning('Product added to cart!', NotificationDuration.MEDIUM)
  }

  const productCard = (
    <div class={style.productCard}>
      <div class={style.previewImagesWrapper}>
        {getPreviewImages()}
      </div>
      <div class={style.productImageWrapper}>
        <ProductPreview key={generateUuidv4()} product={_product} />
      </div>
      <div class={style.productDetailsWrapper}>
        <div class={style.productDetails}>
          <div class={style.productDetailsHeader}>
            Recommended Product
          </div>
          <div class={style.productDescription}>
            {getProductDescription()}
          </div>
          <div class={style.productRating}>
            <Icon icon="stars" />
            <div class={style.ratingText}>(5.0) 1000 ratings</div>
          </div>
          <div class={style.productPrice}>
            $X.XX
          </div>
          <div class={style.addToCart}>
            <div class={style.selectQty}>
              1
              <Icon icon="down-carrot" iconWrapperClass={style.selectQtyIconWrapper} />
            </div>
            <Button buttonType="primary" onClick={handleAddProductToCart}>Add to cart</Button>
          </div>
        </div>
        <div class={style.recommendedProducts}>
          <div class={style.productDetailsHeader}>
            Customers also bought these products
          </div>
          <div class={style.productsList}>
            <div class={`${style.productOption} ${_product === 'disinfecting-wipes' ? style.hide : ''}`} onClick={() => setProduct('disinfecting-wipes')}>
              <img src="/assets/images/clorox-wipes-img.png" />
            </div>
            <div class={`${style.productOption} ${_product === 'disinfecting-mist' ? style.hide : ''}`} onClick={() => setProduct('disinfecting-mist')}>
              <img src="/assets/images/clorox-mist-img.png" />
            </div>
            <div class={`${style.productOption} ${_product === 'disinfecting-mopping-cloth' ? style.hide : ''}`} onClick={() => setProduct('disinfecting-mopping-cloth')}>
              <img src="/assets/images/clorox-mop-cloths-img.png" />
            </div>
            <div class={`${style.productOption} ${_product === 'toilet-wand' ? style.hide : ''}`} onClick={() => setProduct('toilet-wand')}>
              <img src="/assets/images/clorox-wand-img.png" />
            </div>
            <div class={`${style.productOption} ${_product === 'tilex-daily-shower-cleaner' ? style.hide : ''}`} onClick={() => setProduct('tilex-daily-shower-cleaner')}>
              <img src="/assets/images/tilex-cleaner-img.png" />
            </div>
            <div class={`${style.productOption} ${_product === 'windex-glass-cleaner' ? style.hide : ''}`} onClick={() => setProduct('windex-glass-cleaner')}>
              <img src="/assets/images/windex-glass-cleaner-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )


  const getVideo = () => {
    switch (_product) {
      case 'disinfecting-wipes':
        return <video src="/assets/videos/wipes.mp4" controls />
      case 'disinfecting-mist':
        return <img src="/assets/images/clorox-mist-vid-placeholder.png" />
      case 'disinfecting-mopping-cloth':
        return <video src="/assets/videos/mop-cloths.mp4" controls />
      case 'tilex-daily-shower-cleaner':
        return <video src="/assets/videos/tilex.mp4" controls />
      case 'toilet-wand':
        return <video src="/assets/videos/wand.mp4" controls />
      case 'windex-glass-cleaner':
        return <video src="/assets/videos/windex.mp4" controls />
    }
  }

  const getCleaningTips = () => {
    switch (_product) {
      case 'disinfecting-wipes':
        return <img src="/assets/images/clorox-wipes-cleaning-tips.png" class={style.previewImagesImage} />
      case 'disinfecting-mist':
        return <img src="/assets/images/clorox-mist-cleaning-tips.png" class={style.previewImagesImage} />
      case 'disinfecting-mopping-cloth':
        return <img src="/assets/images/clorox-mop-cloths-cleaning-tips.png" class={style.previewImagesImage} />
      case 'tilex-daily-shower-cleaner':
        return <img src="/assets/images/tilex-cleaning-tips.png" class={style.previewImagesImage} />
      case 'toilet-wand':
        return <img src="/assets/images/clorox-wand-cleaning-tips.png" class={style.previewImagesImage} />
      case 'windex-glass-cleaner':
        return <img src="/assets/images/windex-cleaning-tips.png" class={style.previewImagesImage} />
    }
  }

  const cleaningTips = (
    <div class={style.cleaningTips}>
      <div class={style.sectionLeft}>
        <div class={style.video}>
          {getVideo()}
        </div >
        <div class={style.productVideoSuggestions}>
          <img src="/assets/images/modal-product-video-suggestions.png" />
        </div>
      </div>
      <div class={style.sectionRight}>
        <div class={style.cleaningTipsWrapper}>
          {getCleaningTips()}
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      onClose={onClose}
      title={modalHeader}
      display={display}
    >
      <div class={style.modalContentWrapper}>
        {_category === 'product' ? productCard : cleaningTips}
      </div>
    </Modal>
  )
}

export default ProductModal;