export const data = {
  scene: {
    id: "0-lobby-pano",
    name: "lobby-pano",
    levels: [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      }
    ],
    faceSize: 1750,
    initialViewParameters: {
      pitch: 0,
      yaw: 0,
      fov: 1.5707963267948966
    },
    linkHotspots: [],
    infoHotspots: []
  },
  name: "Lobby",
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: false,
    fullscreenButton: false,
    viewControlButtons: false
  }
};
