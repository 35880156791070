/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Fragment, h } from "preact";
import style from './style.css';
import Marzipano from 'marzipano';
import { data, ProductModalCategoryType, ProductType } from './data';
import InfoHotspot from "./components/InfoHotspot/InfoHotspot";
import ProductModal from "./components/ProductModal/ProductModal";
import Icon from "../../../../components/Icon/Icon";
import { route } from "preact-router";
import { RoutePath } from "../../../../models/route";

interface BathroomPanoState {
  displayProductModal: boolean;
  product: ProductType | null;
  category: ProductModalCategoryType;
}
class BathroomPano extends Component<{}, BathroomPanoState> {
  state: BathroomPanoState = {
    displayProductModal: false,
    product: null,
    category: 'product',
  }

  viewer: any;
  panoEl: HTMLDivElement | null = null;

  componentDidMount() {
    this.initBathroomScene()
  }

  private initBathroomScene = () => {
    const { id, initialViewParameters, levels, faceSize, infoHotspots } = data.scene

    const viewerOpts = {
      controls: { mouseViewMode: 'drag' },
    };

    this.viewer = new Marzipano.Viewer(this.panoEl, viewerOpts);

    const urlPrefix = "/assets/tiles";
    const source = Marzipano.ImageUrlSource.fromString(
      `${urlPrefix}/${id}/{z}/{f}/{y}/{x}.jpg`,
      { cubeMapPreviewUrl: `${urlPrefix}/${id}/preview.jpg` }
    );

    const limiter = Marzipano.RectilinearView.limit.traditional(2 * faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180);
    const view = new Marzipano.RectilinearView(initialViewParameters, limiter);
    const geometry = new Marzipano.CubeGeometry(levels);

    const scene = this.viewer.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    });

    const panoScene = {
      data: data.scene,
      scene,
      view,
    }

    infoHotspots.forEach(spot => {
      const el = document.querySelector(`#${spot.id}`)
      panoScene.scene.hotspotContainer().createHotspot(el, spot.position)
    })

    const guideBoxEl = document.querySelector("#moveAroundGuideBox")
    const guideBoxHotspot = panoScene.scene.hotspotContainer().createHotspot(guideBoxEl, { yaw: -0.17876453271422044, pitch: 0.496688457078589 },
      { perspective: { radius: 740 } })


    setTimeout(() => {
      panoScene.scene.hotspotContainer().destroyHotspot(guideBoxHotspot)
    }, 3000)

    // // DOM elements for view controls.
    const viewUpElement = document.querySelector('#viewUp');
    const viewDownElement = document.querySelector('#viewDown');
    const viewLeftElement = document.querySelector('#viewLeft');
    const viewRightElement = document.querySelector('#viewRight');
    const viewInElement = document.querySelector('#viewIn');
    const viewOutElement = document.querySelector('#viewOut');

    // // Dynamic parameters for controls.
    const velocity = 0.6;
    const friction = 3;

    // // Associate view controls with elements.
    const controls = this.viewer.controls();
    controls.registerMethod('upElement', new Marzipano.ElementPressControlMethod(viewUpElement, 'y', -velocity, friction), true);
    controls.registerMethod('downElement', new Marzipano.ElementPressControlMethod(viewDownElement, 'y', velocity, friction), true);
    controls.registerMethod('leftElement', new Marzipano.ElementPressControlMethod(viewLeftElement, 'x', -velocity, friction), true);
    controls.registerMethod('rightElement', new Marzipano.ElementPressControlMethod(viewRightElement, 'x', velocity, friction), true);
    controls.registerMethod('inElement', new Marzipano.ElementPressControlMethod(viewInElement, 'zoom', -velocity, friction), true);
    controls.registerMethod('outElement', new Marzipano.ElementPressControlMethod(viewOutElement, 'zoom', velocity, friction), true);

    panoScene.scene.switchTo();
  }

  private handleHotspotClick = (p: ProductType, c: ProductModalCategoryType) => {
    this.setState({
      displayProductModal: true,
      product: p,
      category: c
    })
  }

  render() {
    const { displayProductModal, product, category } = this.state;

    return (
      <Fragment>
        {data.scene.infoHotspots.map(spot => {
          return (
            <InfoHotspot
              key={spot.id}
              id={spot.id}
              product={spot.product}
              onClick={this.handleHotspotClick}
            />
          )
        })}

        <img src="/assets/images/move-around-guide-box.png" id="moveAroundGuideBox" />

        <div class={style.panoContainer} ref={pano => this.panoEl = pano} />

        <ProductModal
          display={displayProductModal}
          product={product}
          category={category}
          onClose={() => this.setState({ displayProductModal: false })}
        />

        <footer class={style.footer}>
          <div class={style.footerSection}>
            <div class={style.toLobbyWrapper} onClick={() => route(RoutePath.Lobby, true)} >
              <Icon icon="lobby" iconWrapperClass={style.lobbyIcon} />
              Lobby
            </div>
          </div>

          <div class={style.sceneController}>
            <Icon id="viewIn" icon="zoom-in" iconWrapperClass={style.zoomInIconWrapper} />
            <div class={style.navController}>
              <Icon id="viewUp" icon="up-arrow" iconWrapperClass={style.upArrowIconWrapper} />
              <Icon id="viewLeft" icon="left-arrow" iconWrapperClass={style.leftArrowIconWrapper} />
              <Icon id="viewRight" icon="right-arrow" iconWrapperClass={style.rightArrowIconWrapper} />
              <Icon id="viewDown" icon="down-arrow" iconWrapperClass={style.downArrowIconWrapper} />
              <Icon icon="eye" iconWrapperClass={style.eyeIconWrapper} />
            </div>
            <Icon id="viewOut" icon="zoom-out" iconWrapperClass={style.zoomOutIconWrapper} />
          </div>

          <div class={style.footerSection}>
            <Icon icon="cart" onClick={() => route(RoutePath.Cart, true)} iconWrapperClass={style.cartIcon} />
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default BathroomPano;