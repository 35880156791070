import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { RoutePath } from '../../models/route';
import style from './style.css';

const Cart: FunctionalComponent = () => {
  return (
    <div class={style.cart} style={{ backgroundImage: `url('/assets/images/cart-view.png')` }}>
      <img src="/assets/images/cart-view.png" style={{ visibility: `hidden`, width: '100%' }} />
      <Modal display overlay={false} opacity>
        <div class={style.actionsWrapper}>
          <Button buttonType="dark" onClick={() => route(RoutePath.Home, true)}>Back to Start</Button>
          <Button buttonType="dark" onClick={() => route(RoutePath.Lobby, true)}>Back to Lobby</Button>
          <Button buttonType="dark" onClick={() => route(RoutePath.Bathroom, true)}>Back to Bathroom</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Cart;
