import { FunctionalComponent, h } from 'preact';
import Icon from '../../components/Icon/Icon';
import { route } from 'preact-router';
import { RoutePath } from '../../models/route';
import BathroomPano from './components/BathroomPano/BathroomPano';
import style from './style.css';

const Bathroom: FunctionalComponent = () => {
  return (
    <div class={style.bathroom}>
      <header class={style.header}>
        <div class={`${style.headerSection} ${style.flexStart}`}>
          <a href={RoutePath.Home}>
            <Icon icon="walmart-logo" iconWrapperClass={style.walmartLogo} />
          </a>
          <a href="https://www.clorox.com/">
            <Icon icon="clorox-logo" iconWrapperClass={style.cloroxLogo} />
          </a>
        </div>
        <div class={style.headerSection}>
          Location: At Home
        </div>
        <div class={`${style.headerSection} ${style.flexEnd}`}>
          <div class={style.closeWrapper} onClick={() => route(RoutePath.Lobby, true)}>
            Close
            <Icon icon="close" iconWrapperClass={style.closeIcon} />
          </div>
        </div>
      </header>
      <BathroomPano />
    </div>
  );
};

export default Bathroom;
