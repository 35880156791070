import { FunctionalComponent, h } from 'preact';
import { route, Route, Router } from 'preact-router';
import { RoutePath } from '../models/route';
import AsyncRoute from 'preact-async-route';
import NotFoundPage from '../routes/NotFound/NotFound';
import { isAuthenticated } from '../util';
import EnterPassword from '../routes/EnterPassword/EnterPassword';
import Loader from './Loader/Loader';
import Home from '../routes/Home/Home';
import Lobby from '../routes/Lobby/Lobby';
import Bathroom from '../routes/Bathroom/Bathroom';
import Cart from '../routes/Cart/Cart';

const App: FunctionalComponent = () => {
  const getComponentByPath = (url: string) => {
    switch (url) {
      case RoutePath.Home:
        return Home;
      case RoutePath.Lobby:
        return Lobby;
      case RoutePath.Bathroom:
        return Bathroom;
      case RoutePath.Cart:
        return Cart;
      default:
        return EnterPassword;
    }
  }

  const secureComponentWrapper = async (url: string) => {
    const isAuth = await isAuthenticated()
    if (isAuth) return getComponentByPath(url)
    route(RoutePath.EnterPassword, true)
  }

  return (
    <div id="preact_root">
      <Router>
        <Route path={RoutePath.EnterPassword} component={EnterPassword} />

        <AsyncRoute path={RoutePath.Home} getComponent={secureComponentWrapper} />
        <AsyncRoute path={RoutePath.Lobby} getComponent={secureComponentWrapper} />
        <AsyncRoute path={RoutePath.Bathroom} getComponent={secureComponentWrapper} />
        <AsyncRoute path={RoutePath.Cart} getComponent={secureComponentWrapper} />

        <NotFoundPage default />
      </Router>
    </div>
  );
};

export default App;
